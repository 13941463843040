import axios from 'axios';
import { Formik } from 'formik';
import React, { memo } from 'react'
import * as yup from 'yup';
import './Forma.css'

function Forma({setViewModal}) {

    const validationSchema = yup.object().shape({
        phone: yup
          .string()
          .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'Գրել միայն թիվ')
          .required('Պարտադիր դաշտ')
      });

      const handleFeedback = async(e, handleSubmit, isValid) => {
        handleSubmit()
        e.preventDefault();
        if (isValid) {
         const obj = {
            phone: '+374' + e.target[0].value,
         }

         
        // await axios.post('http://localhost:8000/send-email', obj)
        await axios.post('https://limitmail.trigger.ltd/send-email', obj)
         
        setViewModal(true)
      };
    };

  return (
    <div className='form_div'>
        <Formik
            initialValues={{
              phone: '',
            }}
            onSubmit={(values, { resetForm }) => {
              resetForm();
            }}
            validateOnBlur
            validationSchema={validationSchema}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              dirty,
            }) => (
              <form className="feedBack" onSubmit={(e) => handleFeedback(e, handleSubmit, isValid)}>
                <h3>Մուտք համակարգ</h3>
                  <div className="phone-inp">
                    <p>Բջջային Հեռախոս</p>
                    <div className='phone'>
                      <span>+374</span>
                      <input
                        type="text"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {touched.phone && errors.phone && <p className="error_formik">{errors.phone}</p>}
                  </div>

                <button className='feedBack_btn'>Առաջ</button>
              </form>
            )}
          </Formik>
    </div>
  )
}

export default memo(Forma)